html {
  line-height: 1.5;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  text-align: center;
  font-family: "Oswald", "Noto Sans JP", sans-serif;
}
