h1 {
  font-size: 3rem;
}

body {
  font-size: 0.7rem;
}

.App {
  margin-bottom: 5rem;
}

.App__section {
  max-width: 1024px;
  margin: 2rem auto;
  text-align: center;
}

.list {
  padding: 0;
}

.list--sns {
  display: flex;
  justify-content: center;
}

.item {
  list-style: none;
}

.item--icon {
  position: relative;
  list-style: none;
  margin: 8px;
}

.icon {
  position: relative;
  display: inline-block;
  top: -1px;
  margin-right: 4px;
  font-size: 0.9rem;
  line-height: 1;
  vertical-align: middle;
}

.text {
  margin: 1rem;
}

.emal {
  text-align: center;
}

.App__footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 0.6rem;
  text-align: center;
}
